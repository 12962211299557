<script setup lang="ts">
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<{
  label?: string
  name: string
  modelValue?: boolean
  disabled?: boolean
}>(), {
  modelValue: false,
  disabled: false,
})

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const { name } = toRefs(props)

const { value } = useField(name, undefined, {
  initialValue: props.modelValue,
})

watch(value, val => emits('update:modelValue', val))
</script>

<template>
  <div class="relative flex items-start">
    <div class="flex h-6 items-center">
      <input
        v-model="value"
        :name="name"
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        :class="[
          disabled && 'cursor-not-allowed opacity-50',
        ]"
        :disabled="disabled"
      >
    </div>
    <div class="ml-3 text-sm leading-6">
      <label :for="name">
        <slot name="label" :value="value">
          {{ label }}
        </slot>
      </label>
    </div>
  </div>
</template>
